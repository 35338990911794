import React, { useEffect } from 'react';
import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Container,
  Left,
  Right,
  TextContainer,
  TextTitle,
  TextContent,
  ImageContainer,
} from '@/components/Pages/Contato/styles';
import { StaticImage } from 'gatsby-plugin-image';

const Contato = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "contato" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);
  const SEOData = directus.site_pages[0]?.seo;

  useEffect(() => {
    if (!document.getElementById('rd-form-script')) {
      const script = document.createElement('script');
      script.id = 'rd-form-script';
      script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
      script.async = true;
      script.onload = () => {
        if ((window as any).RDStationForms) {
          const existingForms = document.querySelectorAll("#v4-form-site-80af8e0f78c4935d6ea7 form");
          existingForms.forEach(form => form.remove());

          new (window as any).RDStationForms('v4-form-site-80af8e0f78c4935d6ea7', 'null').createForm();
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <SEO
        pageTitle="Contato"
        title={SEOData?.title}
        description={SEOData?.description}
        image={`${process.env.CMS_URL}/assets/${SEOData?.image}`}
        keywords={SEOData?.keywords}
      />
      <Layout>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f4f8ff',
          }}
        >
          <Container>
            <Left>
              <div role="main" id="v4-form-site-80af8e0f78c4935d6ea7"></div>
            </Left>
            <Right>
              <TextContainer>
                <TextTitle>Seja Bem Vindo(a)!</TextTitle>
                <TextContent>
                  Que bom ver você por aqui! <br /> <br /> Se você está
                  buscando uma transportadora de compromisso que oferece uma
                  entrega rápida e segura, escolha a Generoso! <br /> <br />
                  Nosso <span style={{ color: '#407eca' }}>time comercial</span>{' '}
                  está preparado para apresentar as melhores soluções para o seu
                  negócio. Seja Generoso!
                </TextContent>
              </TextContainer>
              <ImageContainer>
                <StaticImage
                  src="../images/paginas/index/mulher-generoso-frente.png"
                  quality={100}
                  alt="Mulher generoso frente"
                  placeholder="blurred"
                />
              </ImageContainer>
            </Right>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default Contato;
